
import headerTab from "~/components/common/header";
// import footerTab from '~/components/common/footer'
import cookies from "@/utils/cookies";
import { setStore, removeStore } from "@/utils/storage";
import { downloadlibrary } from "~/api/course.js";
import { gaodeMap } from "~/utils/amap";
import { mapGetters } from "vuex";
import watermark from "@/utils/watermark.js";
import { getStore } from "@/utils/storage";
import { nextTick } from "vue";
export default {
  name: "Default",
  components: {
    headerTab,
    // footerTab
  },
  props: {},
  data() {
    return {};
  },
  head() {
    return {
      title: this.$store.state.clientData.name || "",
      link: [
        {
          rel: "icon",
          type: "image/x-icon",
          href: this.$store.state.webInfo
            ? this.$store.state.webInfo.websiteIco
            : "",
        },
        { rel: "stylesheet", type: "text/css", href: "/wangEditor.min.css" },
      ],
      script: [
        { src: "//lib.baomitu.com/jquery/3.5.1/jquery.min.js" },
        { src: "//live-cdn.baijiayun.com/bplayer/latest/bplayer.js" },
        { src: "//live-cdn.baijiayun.com/bplayer/latest/dep/ffplayer.js" },
        { src: "//player.polyv.net/script/player.js" },
        { src: "//player.polyv.net/script/polyvplayer.min.js" },
      ],
    };
  },
  computed: {
    ...mapGetters(["webInfo"]),
    // userInfo() {
    //   let user = this.$store.state.userInfo;
    //   if (user) {
    //     return user;
    //   } else {
    //     user = getStore("ReUserInfo");
    //     return user;
    //   }
    // },
    // userInfo() {
    //   return this.$store.state.userInfo || {};
    // },
    service() {
      return this.$store.state.webInfo || {};
    },
  },
  watch: {
    "$store.state.lang"(val) {
      this.$i18n.locale = val;
    },
  },
  created() {},
  mounted() {
    this.$i18n.locale = cookies.getInClient("lang") || "zh";

    // if (this.userInfo) {
    //   setTimeout(() => {
    //     watermark.set(
    //       this.userInfo.userName + " • " + this.userInfo.phone.slice(7, 12)
    //     );
    //   },1000);
    // }
    // console.log(this.userInfo, "userinfo");
    // const userInfo = this.$store.dispatch("GET_USERINFO");
    // const userInfo = { userName: "liuyuming", phone: "18310397731" };
    // console.log(userInfo,'userInfouserInfo')
    this.$store.dispatch("GET_USERINFO").then((res) => {
      const userInfo = res;
      console.log(userInfo, "userInfouserInfo");
      if (userInfo) {
        watermark.set(userInfo.userName + " • " + userInfo.phone.slice(7, 12));
      }
    });

    document.addEventListener("click", (e) => {
      // console.log(e.target.getAttribute('data-id'), 111)
      if (e && e.target) {
        if (e && e.target) {
          if (
            e.target.parentNode &&
            e.target.parentNode.className &&
            e.target.parentNode.className.indexOf("file_a") > -1 &&
            e.target.parentNode.getAttribute("data-id")
          ) {
            this.downloadlibrary(e.target.parentNode.getAttribute("data-id"));
            return;
          }
          if (
            e.target &&
            e.target.className &&
            e.target.className.indexOf("file_a") > -1 &&
            e.target.getAttribute("data-id")
          ) {
            this.downloadlibrary(e.target.getAttribute("data-id"));
            return;
          }
        }
      }
    });

    if (this.webInfo.amapKeyId && this.webInfo.amapKeySecret) {
      gaodeMap(this.webInfo.amapKeyId, this.webInfo.amapKeySecret);
    }
  },
  methods: {
    downloadlibrary(id) {
      downloadlibrary(id).then((res) => {
        if (res) {
          setStore("is_download_file", true);
          window.open(res, "_blnak");
          setTimeout(() => {
            removeStore("is_download_file");
          }, 2000);
        }
      });
    },
  },
};
