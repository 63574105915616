export const AnnouncementView = () => import('../..\\components\\announcementView.vue' /* webpackChunkName: "components/announcement-view" */).then(c => wrapFunctional(c.default || c))
export const HeaderList = () => import('../..\\components\\HeaderList.vue' /* webpackChunkName: "components/header-list" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../..\\components\\Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const Sign = () => import('../..\\components\\sign.vue' /* webpackChunkName: "components/sign" */).then(c => wrapFunctional(c.default || c))
export const Wangeditor = () => import('../..\\components\\wangeditor.vue' /* webpackChunkName: "components/wangeditor" */).then(c => wrapFunctional(c.default || c))
export const AccountEvaluateCourse = () => import('../..\\components\\account\\evaluateCourse.vue' /* webpackChunkName: "components/account-evaluate-course" */).then(c => wrapFunctional(c.default || c))
export const AccountEvaluateLecturer = () => import('../..\\components\\account\\evaluateLecturer.vue' /* webpackChunkName: "components/account-evaluate-lecturer" */).then(c => wrapFunctional(c.default || c))
export const AccountEvaluateTrainees = () => import('../..\\components\\account\\evaluateTrainees.vue' /* webpackChunkName: "components/account-evaluate-trainees" */).then(c => wrapFunctional(c.default || c))
export const AccountExamDialog = () => import('../..\\components\\account\\examDialog.vue' /* webpackChunkName: "components/account-exam-dialog" */).then(c => wrapFunctional(c.default || c))
export const AccountLecturerEvaluate = () => import('../..\\components\\account\\lecturerEvaluate.vue' /* webpackChunkName: "components/account-lecturer-evaluate" */).then(c => wrapFunctional(c.default || c))
export const AccountPracDialog = () => import('../..\\components\\account\\pracDialog.vue' /* webpackChunkName: "components/account-prac-dialog" */).then(c => wrapFunctional(c.default || c))
export const AccountRecord = () => import('../..\\components\\account\\record.vue' /* webpackChunkName: "components/account-record" */).then(c => wrapFunctional(c.default || c))
export const AccountRecordList = () => import('../..\\components\\account\\recordList.vue' /* webpackChunkName: "components/account-record-list" */).then(c => wrapFunctional(c.default || c))
export const AccountStudyTask = () => import('../..\\components\\account\\studyTask.vue' /* webpackChunkName: "components/account-study-task" */).then(c => wrapFunctional(c.default || c))
export const AccountTraineesEvaluate = () => import('../..\\components\\account\\traineesEvaluate.vue' /* webpackChunkName: "components/account-trainees-evaluate" */).then(c => wrapFunctional(c.default || c))
export const AccountUpload = () => import('../..\\components\\account\\upload.vue' /* webpackChunkName: "components/account-upload" */).then(c => wrapFunctional(c.default || c))
export const AccountUserAnnouncement = () => import('../..\\components\\account\\userAnnouncement.vue' /* webpackChunkName: "components/account-user-announcement" */).then(c => wrapFunctional(c.default || c))
export const AccountUserEvaluate = () => import('../..\\components\\account\\userEvaluate.vue' /* webpackChunkName: "components/account-user-evaluate" */).then(c => wrapFunctional(c.default || c))
export const AccountUserEvaluateMy = () => import('../..\\components\\account\\userEvaluateMy.vue' /* webpackChunkName: "components/account-user-evaluate-my" */).then(c => wrapFunctional(c.default || c))
export const AccountUserFile = () => import('../..\\components\\account\\userFile.vue' /* webpackChunkName: "components/account-user-file" */).then(c => wrapFunctional(c.default || c))
export const AccountUserMsg = () => import('../..\\components\\account\\userMsg.vue' /* webpackChunkName: "components/account-user-msg" */).then(c => wrapFunctional(c.default || c))
export const AccountUserMyEvaluate = () => import('../..\\components\\account\\userMyEvaluate.vue' /* webpackChunkName: "components/account-user-my-evaluate" */).then(c => wrapFunctional(c.default || c))
export const AccountUserOperate = () => import('../..\\components\\account\\userOperate.vue' /* webpackChunkName: "components/account-user-operate" */).then(c => wrapFunctional(c.default || c))
export const AccountUserRecord = () => import('../..\\components\\account\\userRecord.vue' /* webpackChunkName: "components/account-user-record" */).then(c => wrapFunctional(c.default || c))
export const AccountUserStudentCourse = () => import('../..\\components\\account\\userStudentCourse.vue' /* webpackChunkName: "components/account-user-student-course" */).then(c => wrapFunctional(c.default || c))
export const AccountUserTask = () => import('../..\\components\\account\\userTask.vue' /* webpackChunkName: "components/account-user-task" */).then(c => wrapFunctional(c.default || c))
export const AccountUserTeacherCourse = () => import('../..\\components\\account\\userTeacherCourse.vue' /* webpackChunkName: "components/account-user-teacher-course" */).then(c => wrapFunctional(c.default || c))
export const AddAttendance = () => import('../..\\components\\addDialog\\addAttendance.vue' /* webpackChunkName: "components/add-attendance" */).then(c => wrapFunctional(c.default || c))
export const AddExam = () => import('../..\\components\\addDialog\\addExam.vue' /* webpackChunkName: "components/add-exam" */).then(c => wrapFunctional(c.default || c))
export const AddHomework = () => import('../..\\components\\addDialog\\addHomework.vue' /* webpackChunkName: "components/add-homework" */).then(c => wrapFunctional(c.default || c))
export const AddInvestigate = () => import('../..\\components\\addDialog\\addInvestigate.vue' /* webpackChunkName: "components/add-investigate" */).then(c => wrapFunctional(c.default || c))
export const AddLocation = () => import('../..\\components\\addDialog\\addLocation.vue' /* webpackChunkName: "components/add-location" */).then(c => wrapFunctional(c.default || c))
export const AddOfflineCourse = () => import('../..\\components\\addDialog\\addOfflineCourse.vue' /* webpackChunkName: "components/add-offline-course" */).then(c => wrapFunctional(c.default || c))
export const CommonCodeButton = () => import('../..\\components\\common\\CodeButton.vue' /* webpackChunkName: "components/common-code-button" */).then(c => wrapFunctional(c.default || c))
export const CommonFooter = () => import('../..\\components\\common\\footer.vue' /* webpackChunkName: "components/common-footer" */).then(c => wrapFunctional(c.default || c))
export const CommonHeader = () => import('../..\\components\\common\\header.vue' /* webpackChunkName: "components/common-header" */).then(c => wrapFunctional(c.default || c))
export const CommonMinFooter = () => import('../..\\components\\common\\minFooter.vue' /* webpackChunkName: "components/common-min-footer" */).then(c => wrapFunctional(c.default || c))
export const CustomLink = () => import('../..\\components\\custom\\link.vue' /* webpackChunkName: "components/custom-link" */).then(c => wrapFunctional(c.default || c))
export const DialogAttachmentDetail = () => import('../..\\components\\dialog\\attachmentDetail.vue' /* webpackChunkName: "components/dialog-attachment-detail" */).then(c => wrapFunctional(c.default || c))
export const DialogAttendanceQrCode = () => import('../..\\components\\dialog\\attendanceQrCode.vue' /* webpackChunkName: "components/dialog-attendance-qr-code" */).then(c => wrapFunctional(c.default || c))
export const DialogExamDialog = () => import('../..\\components\\dialog\\examDialog.vue' /* webpackChunkName: "components/dialog-exam-dialog" */).then(c => wrapFunctional(c.default || c))
export const DialogRecordDialog = () => import('../..\\components\\dialog\\recordDialog.vue' /* webpackChunkName: "components/dialog-record-dialog" */).then(c => wrapFunctional(c.default || c))
export const DialogShowVideo = () => import('../..\\components\\dialog\\showVideo.vue' /* webpackChunkName: "components/dialog-show-video" */).then(c => wrapFunctional(c.default || c))
export const DevelopmentAbilityExplain = () => import('../..\\components\\development\\abilityExplain.vue' /* webpackChunkName: "components/development-ability-explain" */).then(c => wrapFunctional(c.default || c))
export const DevelopmentHistoryEvaluation = () => import('../..\\components\\development\\historyEvaluation.vue' /* webpackChunkName: "components/development-history-evaluation" */).then(c => wrapFunctional(c.default || c))
export const HomeBanner = () => import('../..\\components\\home\\Banner.vue' /* webpackChunkName: "components/home-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeCourse = () => import('../..\\components\\home\\Course.vue' /* webpackChunkName: "components/home-course" */).then(c => wrapFunctional(c.default || c))
export const HomeExamBoard = () => import('../..\\components\\home\\ExamBoard.vue' /* webpackChunkName: "components/home-exam-board" */).then(c => wrapFunctional(c.default || c))
export const HomeExamRank = () => import('../..\\components\\home\\examRank.vue' /* webpackChunkName: "components/home-exam-rank" */).then(c => wrapFunctional(c.default || c))
export const HomeExamRankDialog = () => import('../..\\components\\home\\ExamRankDialog.vue' /* webpackChunkName: "components/home-exam-rank-dialog" */).then(c => wrapFunctional(c.default || c))
export const HomeLecturer = () => import('../..\\components\\home\\Lecturer.vue' /* webpackChunkName: "components/home-lecturer" */).then(c => wrapFunctional(c.default || c))
export const HomeNotice = () => import('../..\\components\\home\\Notice.vue' /* webpackChunkName: "components/home-notice" */).then(c => wrapFunctional(c.default || c))
export const HomeRank = () => import('../..\\components\\home\\Rank.vue' /* webpackChunkName: "components/home-rank" */).then(c => wrapFunctional(c.default || c))
export const HomeRankDialog = () => import('../..\\components\\home\\RankDialog.vue' /* webpackChunkName: "components/home-rank-dialog" */).then(c => wrapFunctional(c.default || c))
export const HomeShop = () => import('../..\\components\\home\\Shop.vue' /* webpackChunkName: "components/home-shop" */).then(c => wrapFunctional(c.default || c))
export const HomeStudyItem = () => import('../..\\components\\home\\StudyItem.vue' /* webpackChunkName: "components/home-study-item" */).then(c => wrapFunctional(c.default || c))
export const HomeTask = () => import('../..\\components\\home\\Task.vue' /* webpackChunkName: "components/home-task" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeCatePanelChild = () => import('../..\\components\\knowledge\\catePanelChild.vue' /* webpackChunkName: "components/knowledge-cate-panel-child" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeChildResource = () => import('../..\\components\\knowledge\\childResource.vue' /* webpackChunkName: "components/knowledge-child-resource" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeComment = () => import('../..\\components\\knowledge\\comment.vue' /* webpackChunkName: "components/knowledge-comment" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeCourseDisplay = () => import('../..\\components\\knowledge\\courseDisplay.vue' /* webpackChunkName: "components/knowledge-course-display" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeCourseList = () => import('../..\\components\\knowledge\\courseList.vue' /* webpackChunkName: "components/knowledge-course-list" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeCourseware = () => import('../..\\components\\knowledge\\courseware.vue' /* webpackChunkName: "components/knowledge-courseware" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeEvaluation = () => import('../..\\components\\knowledge\\Evaluation.vue' /* webpackChunkName: "components/knowledge-evaluation" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeGrade = () => import('../..\\components\\knowledge\\Grade.vue' /* webpackChunkName: "components/knowledge-grade" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeGradeGroup = () => import('../..\\components\\knowledge\\GradeGroup.vue' /* webpackChunkName: "components/knowledge-grade-group" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeOfflineChild = () => import('../..\\components\\knowledge\\offlineChild.vue' /* webpackChunkName: "components/knowledge-offline-child" */).then(c => wrapFunctional(c.default || c))
export const KnowledgePreview = () => import('../..\\components\\knowledge\\preview.vue' /* webpackChunkName: "components/knowledge-preview" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeProjectDisplay = () => import('../..\\components\\knowledge\\projectDisplay.vue' /* webpackChunkName: "components/knowledge-project-display" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeProjectList = () => import('../..\\components\\knowledge\\projectList.vue' /* webpackChunkName: "components/knowledge-project-list" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeProjectRecord = () => import('../..\\components\\knowledge\\projectRecord.vue' /* webpackChunkName: "components/knowledge-project-record" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeProjectSyllabus = () => import('../..\\components\\knowledge\\projectSyllabus.vue' /* webpackChunkName: "components/knowledge-project-syllabus" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeRecord = () => import('../..\\components\\knowledge\\record.vue' /* webpackChunkName: "components/knowledge-record" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeSignChild = () => import('../..\\components\\knowledge\\signChild.vue' /* webpackChunkName: "components/knowledge-sign-child" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeSyllabus = () => import('../..\\components\\knowledge\\Syllabus.vue' /* webpackChunkName: "components/knowledge-syllabus" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeTaskEvaluateDialog = () => import('../..\\components\\knowledge\\TaskEvaluateDialog.vue' /* webpackChunkName: "components/knowledge-task-evaluate-dialog" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeTaskProgress = () => import('../..\\components\\knowledge\\taskProgress.vue' /* webpackChunkName: "components/knowledge-task-progress" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeTaskProgressChild = () => import('../..\\components\\knowledge\\taskProgressChild.vue' /* webpackChunkName: "components/knowledge-task-progress-child" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeVideoModal = () => import('../..\\components\\knowledge\\VideoModal.vue' /* webpackChunkName: "components/knowledge-video-modal" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeWatchVideo = () => import('../..\\components\\knowledge\\WatchVideo.vue' /* webpackChunkName: "components/knowledge-watch-video" */).then(c => wrapFunctional(c.default || c))
export const LecturerAssessUser = () => import('../..\\components\\lecturer\\assessUser.vue' /* webpackChunkName: "components/lecturer-assess-user" */).then(c => wrapFunctional(c.default || c))
export const LecturerAttachment = () => import('../..\\components\\lecturer\\attachment.vue' /* webpackChunkName: "components/lecturer-attachment" */).then(c => wrapFunctional(c.default || c))
export const LecturerCourseList = () => import('../..\\components\\lecturer\\courseList.vue' /* webpackChunkName: "components/lecturer-course-list" */).then(c => wrapFunctional(c.default || c))
export const LecturerEvaluatedUser = () => import('../..\\components\\lecturer\\evaluatedUser.vue' /* webpackChunkName: "components/lecturer-evaluated-user" */).then(c => wrapFunctional(c.default || c))
export const LecturerEvaluationUser = () => import('../..\\components\\lecturer\\evaluationUser.vue' /* webpackChunkName: "components/lecturer-evaluation-user" */).then(c => wrapFunctional(c.default || c))
export const LecturerList = () => import('../..\\components\\lecturer\\list.vue' /* webpackChunkName: "components/lecturer-list" */).then(c => wrapFunctional(c.default || c))
export const LecturerQuestionnaire = () => import('../..\\components\\lecturer\\questionnaire.vue' /* webpackChunkName: "components/lecturer-questionnaire" */).then(c => wrapFunctional(c.default || c))
export const LecturerQuestionnaireGroup = () => import('../..\\components\\lecturer\\questionnaireGroup.vue' /* webpackChunkName: "components/lecturer-questionnaire-group" */).then(c => wrapFunctional(c.default || c))
export const SelectCertificate = () => import('../..\\components\\selectDialog\\SelectCertificate.vue' /* webpackChunkName: "components/select-certificate" */).then(c => wrapFunctional(c.default || c))
export const SelectCourse = () => import('../..\\components\\selectDialog\\SelectCourse.vue' /* webpackChunkName: "components/select-course" */).then(c => wrapFunctional(c.default || c))
export const SelectLucturerAvatar = () => import('../..\\components\\selectDialog\\SelectLucturerAvatar.vue' /* webpackChunkName: "components/select-lucturer-avatar" */).then(c => wrapFunctional(c.default || c))
export const SelectPaper = () => import('../..\\components\\selectDialog\\SelectPaper.vue' /* webpackChunkName: "components/select-paper" */).then(c => wrapFunctional(c.default || c))
export const SelectQuestionnaire = () => import('../..\\components\\selectDialog\\SelectQuestionnaire.vue' /* webpackChunkName: "components/select-questionnaire" */).then(c => wrapFunctional(c.default || c))
export const SelectTree = () => import('../..\\components\\selectDialog\\SelectTree.vue' /* webpackChunkName: "components/select-tree" */).then(c => wrapFunctional(c.default || c))
export const SelectUpload = () => import('../..\\components\\selectDialog\\SelectUpload.vue' /* webpackChunkName: "components/select-upload" */).then(c => wrapFunctional(c.default || c))
export const SelectUser = () => import('../..\\components\\selectDialog\\SelectUser.vue' /* webpackChunkName: "components/select-user" */).then(c => wrapFunctional(c.default || c))
export const SelectUserObject = () => import('../..\\components\\selectDialog\\SelectUserObject.vue' /* webpackChunkName: "components/select-user-object" */).then(c => wrapFunctional(c.default || c))
export const SvgIcon = () => import('../..\\components\\SvgIcon\\index.vue' /* webpackChunkName: "components/svg-icon" */).then(c => wrapFunctional(c.default || c))
export const SvgIconResourceType = () => import('../..\\components\\SvgIcon\\ResourceType.vue' /* webpackChunkName: "components/svg-icon-resource-type" */).then(c => wrapFunctional(c.default || c))
export const Unfold = () => import('../..\\components\\unfold\\index.vue' /* webpackChunkName: "components/unfold" */).then(c => wrapFunctional(c.default || c))
export const UniCollapse = () => import('../..\\components\\uni-collapse\\uni-collapse.vue' /* webpackChunkName: "components/uni-collapse" */).then(c => wrapFunctional(c.default || c))
export const UniCollapseItem = () => import('../..\\components\\uni-collapse-item\\uni-collapse-item.vue' /* webpackChunkName: "components/uni-collapse-item" */).then(c => wrapFunctional(c.default || c))
export const AccountFileListCertificationFile = () => import('../..\\components\\account\\fileList\\certificationFile.vue' /* webpackChunkName: "components/account-file-list-certification-file" */).then(c => wrapFunctional(c.default || c))
export const AccountFileListExamFile = () => import('../..\\components\\account\\fileList\\examFile.vue' /* webpackChunkName: "components/account-file-list-exam-file" */).then(c => wrapFunctional(c.default || c))
export const AccountFileListLogFile = () => import('../..\\components\\account\\fileList\\logFile.vue' /* webpackChunkName: "components/account-file-list-log-file" */).then(c => wrapFunctional(c.default || c))
export const AccountFileListStudyFile = () => import('../..\\components\\account\\fileList\\studyFile.vue' /* webpackChunkName: "components/account-file-list-study-file" */).then(c => wrapFunctional(c.default || c))
export const AccountFileListStudyProjectFile = () => import('../..\\components\\account\\fileList\\studyProjectFile.vue' /* webpackChunkName: "components/account-file-list-study-project-file" */).then(c => wrapFunctional(c.default || c))
export const AccountFileListTaskFile = () => import('../..\\components\\account\\fileList\\taskFile.vue' /* webpackChunkName: "components/account-file-list-task-file" */).then(c => wrapFunctional(c.default || c))
export const DevelopmentEstimateOther = () => import('../..\\components\\development\\estimate\\other.vue' /* webpackChunkName: "components/development-estimate-other" */).then(c => wrapFunctional(c.default || c))
export const DevelopmentEstimateSelf = () => import('../..\\components\\development\\estimate\\self.vue' /* webpackChunkName: "components/development-estimate-self" */).then(c => wrapFunctional(c.default || c))
export const AccountPlanAdd = () => import('../..\\components\\account\\plan\\add.vue' /* webpackChunkName: "components/account-plan-add" */).then(c => wrapFunctional(c.default || c))
export const AccountPlanList = () => import('../..\\components\\account\\plan\\list.vue' /* webpackChunkName: "components/account-plan-list" */).then(c => wrapFunctional(c.default || c))
export const AccountRecordListCertificate = () => import('../..\\components\\account\\recordList\\certificate.vue' /* webpackChunkName: "components/account-record-list-certificate" */).then(c => wrapFunctional(c.default || c))
export const AccountRecordListCertificateRecord = () => import('../..\\components\\account\\recordList\\certificateRecord.vue' /* webpackChunkName: "components/account-record-list-certificate-record" */).then(c => wrapFunctional(c.default || c))
export const AccountRecordListCertificateView = () => import('../..\\components\\account\\recordList\\certificateView.vue' /* webpackChunkName: "components/account-record-list-certificate-view" */).then(c => wrapFunctional(c.default || c))
export const AccountRecordListCourseRecord = () => import('../..\\components\\account\\recordList\\courseRecord.vue' /* webpackChunkName: "components/account-record-list-course-record" */).then(c => wrapFunctional(c.default || c))
export const AccountRecordListCreditRecord = () => import('../..\\components\\account\\recordList\\creditRecord.vue' /* webpackChunkName: "components/account-record-list-credit-record" */).then(c => wrapFunctional(c.default || c))
export const AccountRecordListExchangeRecord = () => import('../..\\components\\account\\recordList\\exchangeRecord.vue' /* webpackChunkName: "components/account-record-list-exchange-record" */).then(c => wrapFunctional(c.default || c))
export const AccountRecordListIntegralData = () => import('../..\\components\\account\\recordList\\integralData.vue' /* webpackChunkName: "components/account-record-list-integral-data" */).then(c => wrapFunctional(c.default || c))
export const AccountRecordListIntegralRecord = () => import('../..\\components\\account\\recordList\\integralRecord.vue' /* webpackChunkName: "components/account-record-list-integral-record" */).then(c => wrapFunctional(c.default || c))
export const AccountRecordListIntegralRules = () => import('../..\\components\\account\\recordList\\integralRules.vue' /* webpackChunkName: "components/account-record-list-integral-rules" */).then(c => wrapFunctional(c.default || c))
export const AccountRecordListWatchCertificate = () => import('../..\\components\\account\\recordList\\watchCertificate.vue' /* webpackChunkName: "components/account-record-list-watch-certificate" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeResourceChild = () => import('../..\\components\\knowledge\\resource\\child.vue' /* webpackChunkName: "components/knowledge-resource-child" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeResource = () => import('../..\\components\\knowledge\\resource\\index.vue' /* webpackChunkName: "components/knowledge-resource" */).then(c => wrapFunctional(c.default || c))
export const IntegralGoodsCondition = () => import('../..\\components\\integral\\goods\\condition.vue' /* webpackChunkName: "components/integral-goods-condition" */).then(c => wrapFunctional(c.default || c))
export const IntegralGoodsDisplay = () => import('../..\\components\\integral\\goods\\display.vue' /* webpackChunkName: "components/integral-goods-display" */).then(c => wrapFunctional(c.default || c))
export const IntegralGoodsList = () => import('../..\\components\\integral\\goods\\list.vue' /* webpackChunkName: "components/integral-goods-list" */).then(c => wrapFunctional(c.default || c))
export const IntegralGoodsOrder = () => import('../..\\components\\integral\\goods\\order.vue' /* webpackChunkName: "components/integral-goods-order" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
